import React from "react";
import "./termsAndConditionsInfo.css";

export const TermsAndConditionsInfo = () => {
  return (
    <div
      className="termsAndConditionsInfoWrapper"
      data-aos="zoom-in"
      data-aos-duration="1000"
    >
      <h1>Obchodní podmínky</h1>
      <hr />
      <div className="terms-container">
        <section className="section">
          <h2 className="section-heading">VŠEOBECNÁ USTANOVENÍ</h2>
          <p>
            Tyto obchodní podmínky upravují vztahy mezi kupujícím a prodávajícím
            při prodeji zboží prostřednictvím e-shopu provozovaného společností
            Gazar Group s.r.o., se sídlem Liškova 1770/64, Předměstí, 412 01
            Litoměřice, IČO: 21142106, zapsanou v obchodním rejstříku vedeném
            Městským soudem v Ústí nad Labem, spisová značka C 51646 (dále jen
            „prodávající“). Kupující učiněním závazné objednávky potvrzuje, že
            se seznámil s těmito obchodními podmínkami a souhlasí s nimi.
          </p>
        </section>

        <section className="section">
          <h2 className="section-heading">OBJEDNÁNÍ ZBOŽÍ</h2>
          <p>
            Nákup lze uskutečnit online prostřednictvím e-shopu nebo e-mailem.
            Předmětem kupní smlouvy je pouze zboží uvedené v objednávce. Kupní
            smlouva vzniká potvrzením objednávky prodávajícím a následným
            dodáním zboží.
          </p>
        </section>

        <section className="section">
          <h2 className="section-heading">POTVRZENÍ OBJEDNÁVKY</h2>
          <p>
            Objednávka je potvrzena do 24 hodin e-mailem. O odeslání zboží
            budete rovněž informováni e-mailem.
          </p>
        </section>

        <section className="section">
          <h2 className="section-heading">ZRUŠENÍ OBJEDNÁVKY</h2>
          <p>
            Objednávku lze zrušit nejpozději následující den po jejím odeslání
            telefonicky nebo e-mailem.
          </p>
        </section>

        <section className="section">
          <h2 className="section-heading">NEPŘEVZETÍ ZBOŽÍ</h2>
          <p>
            Kupující je povinen objednané zboží převzít. V případě jeho
            nepřevzetí je povinen uhradit náklady na opětovné doručení (99 Kč).
            Pokud tak neučiní, bude mu zaslána faktura. V případě jejího
            neuhrazení bude pohledávka postoupena právnímu zástupci.
          </p>
        </section>

        <section className="section">
          <h2 className="section-heading">DODACÍ LHŮTA</h2>
          <p>
            Zboží je doručováno do 7 pracovních dnů. Pokud nebude skladem,
            kupující bude neprodleně informován.
          </p>
        </section>

        <section className="section">
          <h2 className="section-heading">VÝMĚNA ZBOŽÍ</h2>
          <p>
            Kupující může nepoužité a nepoškozené zboží vyměnit. Náklady na
            vrácení a nové doručení nese kupující.
          </p>
        </section>

        <section className="section">
          <h2 className="section-heading">DOPRAVA A DORUČENÍ ZBOŽÍ</h2>
          <p>
            Doprava balíku je zajišťována primárně přes PPL. V případě jiných
            okolností nebo specifických požadavků může být zásilka doručena
            prostřednictvím České pošty. Pro nadrozměrné zásilky je využívána
            společnost GLS.
          </p>

          <p>Cena dopravy je stanovena v závislosti na velikosti balíku:</p>

          <p>
            Malý balík (do 30x30x30cm) - 76Kč
            <br />
            Střední balík (do 60x40x30cm) - 78Kč
            <br />
            Velký balík (do 100x50x50cm) - 119Kč
            <br />
            Nadrozměrné zásilky (nad uvedené rozměry) – cena individuálně dle
            dopravce
          </p>

          <p>
            Dodací lhůty se obvykle pohybují v rozmezí 1–3 pracovních dnů, v
            závislosti na zvoleném dopravci a destinaci doručení. Kupující je
            povinen při převzetí zboží zkontrolovat neporušenost obalu a v
            případě poškození ihned informovat dopravce a prodávajícího.
            Prodávající si vyhrazuje právo změny dopravce v případě nečekáných
            okolností nebo v případě, že konkrétní dopravce není v danou chvíli
            dostupný.
          </p>
        </section>

        <section className="section">
          <h2 className="section-heading">REKLAMACE A ZÁRUKA</h2>
          <p>
            <>
              Na zboží se vztahuje záruka 24 měsíců. Reklamaci lze uplatnit
              písemně e-mailem nebo poštou. <br></br>
              Postup reklamace:
              <br />
              <br />
              1. Informovat prodávajícího.
              <br />
              2. Zaslat zboží doporučeným balíkem (ne na dobírku).
              <br />
              3. Přiložit doklad o koupi.
              <br />
              4. Reklamace bude vyřízena do 30 dnů.
              <br />
            </>
          </p>
        </section>

        <section className="section">
          <h2 className="section-heading">MIMOSOUDNÍ ŘEŠENÍ SPORŮ</h2>
          <p>
            V případě sporu má kupující právo obrátit se na Českou obchodní
            inspekci (<a href="https://coi.gov.cz/">www.coiz.gov.cz</a>) jako
            subjekt mimosoudního řešení spotřebitelských sporů.
          </p>
        </section>

        <section className="section">
          <h2 className="section-heading">OCHRANA OSOBNÍCH ÚDAJŮ</h2>
          <p>
            Osobní údaje jsou zpracovávány dle zákona č. 110/2019 Sb., o ochraně
            osobních údajů a GDPR.
          </p>
        </section>

        {/* Repeat similar structures for other sections */}
      </div>
    </div>
  );
};
